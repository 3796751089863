.table-td{
    border-radius: 16px;
    width: 329px;
    height: 65px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
}

.table input{
    text-align: center;
    border-radius: 16px;
    border: none;
    width: 329px;
    height: 65px;
}