@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
.login{
  display:flex;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Lato', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

.div-menu { 
    grid-area: 'menu';
    width: 5vw;
    height: 100vh;
}

.div-page { 
    grid-area: 'main';
    width: 95vw;
    height: 100vh;
}

.sheets{
    display: grid;
    grid-template-areas:
    'menu main'
    'menu main'
    'menu main'
    'menu main';
    grid-gap: 0;
    height: 100vh;
    overflow-x: hidden;
}

.div-side-bar{
    height: 100%;
    width: 100%;
    float: left;
    background-color: #FF7501;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.div-title{
    width: 100%;
    text-align: left;
}

.div-title h1{
    margin: 35px;
    font-family: Roboto;
}

.table{
    width: 80%;
    height: 40%;
    overflow: auto;
}

.PDFcontainer{
    display: flex;
    flex-direction: row;
}

.table-th{
    background-color: #302C22;
    border-radius: 16px;
    color: white;
    width: 329px;
    height: 55px;
    font-family: 'Roboto', sans-serif; 
    font-size: 24px;
    font-weight: 900;
    margin: 0 auto;
}

.table-sp{
    height: 40%;
    overflow: auto;
}

.table-sp-th{
    background-color: #302C22;
    border-radius: 16px;
    color: white;
    width: 144px;
    height: 55px;
    font-family: 'Roboto', sans-serif; 
    font-size: 24px;
    font-weight: 900;
    margin: 0 auto;
}

.button-icon-navbar, a{
    border: none;
    background: none;
    cursor: pointer;
    margin: 7px;
}

.div-icon-home{
    height: 20vh;
}

.button-icon-home{
    border: none;
    background: none;
    cursor: pointer;
    margin: 0 auto;
}

.div-table{
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 50vh;
    overflow: auto;
}

.div-table::-webkit-scrollbar{
    width: 5px;
}

.div-table::-webkit-scrollbar-thumb {
    background: #302C22;
    border-radius: 40px;
    width: 4px;
}


.div-table-information{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2vh;
}

.table-info-th{
    background-color: #302C22;
    border-radius: 16px;
    color: white;
    width: 150px;
    height: 55px;
    font-family: 'Roboto', sans-serif; 
    font-size: 16px;
    font-weight: 900;
    margin: 0 auto;
}

.table-info-td{
    border-radius: 16px;
    width: 150px;
    height: 89px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    text-align: center;
}

.table-general-information input{
    text-align: center;
    border-radius: 16px;
    border: none;
    width: 144px;
    height: 89px;
}

#fibo1 {
    width: 60%;
    margin: 0 auto;
}

#btn-close-modal {
    margin-right: -1px;
    width: 5%;
    background: none;
    border: none;
    cursor: pointer;
}

.div-button-close-modal {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.table-td{
    border-radius: 16px;
    width: 329px;
    height: 65px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
}

.table input{
    text-align: center;
    border-radius: 16px;
    border: none;
    width: 329px;
    height: 65px;
}
.table-sp-td{
    border-radius: 16px;
    width: 144px;
    height: 65px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
}
.table-sp input{
    text-align: center;
    border-radius: 16px;
    border: none;
    width: 144px;
    height: 65px;
}

.popup {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-popup {
    position: absolute;
    z-index: 1;
    padding: 32px;
    width: 100%;
    height: 55%;
    max-width: 648px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: left;
    border-radius: 10px;
}

.div-confirmed-action {
    width: 100%;
    height: 100%;
}

.div-text-confirmed-action {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
}

.div-text-confirmed-action-home {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
}

.div-btns-confirmed-action {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div-btns-confirmed-action button {
    font-size: 110%;
    font-family: Lato;
    width: 35vw;
    height: 9vh;
    border-radius: 10px;
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: none;
}

.btn-cancel {
    background-color:#B22222;
    color: white;
}

.btn-cancel:hover {
    background-color:#8B0000;
}

.btn-confirm {
    background-color:#3CB371;
    color: white;
}

.btn-confirm:hover {
    background-color:#2E8B57;
}

@media only screen and (min-width: 425px) { 
    .div-confirmed-action {
        font-size: 100%;
    }
    .div-btns-confirmed-action button {
        height: 8vh;
    }
}

@media only screen and (min-width: 768px) { 
    .div-confirmed-action {
        font-size: 100%;
    }
    .div-btns-confirmed-action button {
        height: 9vh;
        width: 20vw;
    }
}

@media only screen and (min-width: 1024px) { 
    .div-confirmed-action {
        font-size: 100%;
    }
    .div-btns-confirmed-action button {
        height: 8vh;
    }
}

@media only screen and (min-width: 1840px) { 
    .div-confirmed-action {
        font-size: 120%;
    }
    .div-btns-confirmed-action button {
        height: 8vh;
        width: 20vw;
    }
}

@media only screen and (min-width: 2240px) { 
    .div-confirmed-action {
        font-size: 140%;
    }
    .div-btns-confirmed-action button {
        height: 7vh;
        width: 10vw;
    }
}

.login{
    display:flex;
    height: 100vh;
    width: 100%;

background: linear-gradient(0.37deg, #FF7501 -18.83%, #FFFFFF 14.95%);


}

.CornerTires img{
    position: absolute;
    left: 0px;
    max-width: 150;
    width: 220px;
    max-height: 150;
    height: auto;

}

.logo img{
    position: absolute;
    left: 300px;
    top: 200px;
    max-width: 150;
    width: 500px;
    max-height: 150;
    height: auto;
}

.login-right {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    left: 900px;
    top: 150px;
    
}

.UserIcon img{
    margin-bottom: 40px;
    max-width: 150;
    width: 130px;
    max-height: 100;
    height: auto;
}

.loginInputEmail input{
    margin-block: 5px;
    border: none;
    background: #FFB77B;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 5px 25px;
}

.loginInputPassword input{
    margin-block: 5px;
    border: none;
    background: #FFB77B;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 5px 25px;

}

.forgotPassword button{
    margin-block: 5px;
    cursor: pointer;
    background: none;
    border: 0px;
    float: right;
    font-size: 10px;
}

.loginButton button{
    

    margin-block: 15px;
    padding: 4px 65px;
    cursor: pointer;
    border: 3px;
    background: #FFB77B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
}
@charset "UTF-8";

* {
    margin:0;
}

body {
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    margin:0;
    padding: 0;
    font-family: 'Lato', sans-serif;
}
/*header config start*/
header {
    flex-shrink: 0;
    display: flex;
    background-color: #F2A922;
    justify-content: space-between;
    align-items: baseline;
}

header > h1 > span {
    color: rgb(252, 213, 142);
    
}

header > h1 {
    margin: 0 0 0 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.397);
    color: white;
}




.filter_nav {
    display: flex;
    justify-content: space-evenly;
    width: 70%;
    flex-wrap:wrap;
      
}

.header-btn {
    padding: 5px;
    background-color:rgba(255, 255, 255, 0);
    border: none;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 1.3rem;
    margin-bottom: 5px;
    transition: 0.5s;
    padding: 6px;
}

.header-btn:hover {
    cursor: pointer;
    text-decoration: underline;
    border-radius: 10px;
}

.fas {
    margin-right: 10px;
}

/* header config end */
main {
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 100%;
}


/*main config end*/

/*main-container config start*/

.main-container {
    margin: auto;
    flex: 0 1 85vw;
    display: flex;
    flex-flow: column nowrap;
    
}

/*main-container config end*/

/*table and search bar area*/

.search_bar_container {
    height: 50px;
    width: 50vw;
    align-self:center;
    margin-top:20px;
    margin-bottom: 30px;
    display: flex;
}

#pesquisa {
    color: #dd3e0d;
    height: 70%;
    width: 70%;
    margin: auto;
    font-size: 1.2em;
    padding: 2px;
    font-family: 'Lato', sans-serif;
    text-align: center;
    
    border:1px solid rgb(255, 183, 0);
    outline: none;
    border-radius: 5px;
    background-color: whitesmoke;
  
}

#pesquisa:focus {
    border:none;
    outline: none;
    background-color: #f2a92285;
}

.table_container {
    overflow: auto;
    background-color: white;
    border-radius: 20px;
    margin: 10px;
    height: 70vh;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.486);
}

table {
    width: 100%;
    padding:10px;
    position: relative;
}

.table_header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

th {
    background-color: black;
    color: white;
    border-radius: 5px 5px 0 0;
    z-index: 0;
        
}

td {
    text-align: center;
    z-index: 0;
}


.project_name {
   
    background-color: #FF6A48;
}

.project_name > button {
    text-decoration: none;
    cursor: pointer;
    color: black;
    background-color: #FF6A48;
    border: none;
    font-size: 1rem;
    font-weight: 200;
}

.project_status {
    
    background-color: #CDF3C7;
}
.loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
  }  
.div_title {
    height: 11vh;
}
.div_title h1 {
    font-family: 'Lato', sans-serif;
    font-size: 80px;
    margin-left: 12px;
}


.div_button button {
    background-color: coral;
    border: none;
    width: 180px;
    height: 59px;
    font-size: 26px;
    border-radius: 17px;
    float: right;
    cursor: pointer;

}

.div_button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 80px;
    margin-right: 10px;
    

}

.div_sub_title {
    background-color: coral;
    border-radius: 0 29px 0 0;
    width: 548px;

}

.div_inicio {
    margin-right: 8px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 20px;
}

.div_fim {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 20px;
}

.div_status {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 20px;
}

.div_nome {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 20px;
}

.div_fase {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 20px;
}

.div_valor {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 20px;
}

.div_sub_title h3 {
    font-family: 'Lato', sans-serif;
    font-size: 36px;
    margin: 0 auto;
}

.div_information_block {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.div_informations {
    width: 60%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: coral;
    border-right: 1px solid black;
    

}

.div_team {
    width: 40%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: coral;

}

.div_title_team {
    text-align: center;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

.div_employee1 {
    position: relative;
    top: 20px
}

.div_employee2 {
    position: relative;
    top: 20px
}

.div_employee3 {
    position: relative;
    top: 20px
}

.div_photo {
    width: 108px;
    height: 115px;
    border-radius: 14px;
    position: relative;
    top: 20px

}

.div_image {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;


}

.div_selfs {
    text-align: center;
}

.btn-update-information {
    background-color: #2E8B57;
    font-size: 17px;
    color: white;
    width: 25%;
    height: 10%;
    border-radius: 15px;
    border: none;
    cursor: pointer;
    position: relative;
    left: 600px;
}
