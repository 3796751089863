
.login{
    display:flex;
    height: 100vh;
    width: 100%;

background: linear-gradient(0.37deg, #FF7501 -18.83%, #FFFFFF 14.95%);


}

.CornerTires img{
    position: absolute;
    left: 0px;
    max-width: 150;
    width: 220px;
    max-height: 150;
    height: auto;

}

.logo img{
    position: absolute;
    left: 300px;
    top: 200px;
    max-width: 150;
    width: 500px;
    max-height: 150;
    height: auto;
}

.login-right {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    left: 900px;
    top: 150px;
    
}

.UserIcon img{
    margin-bottom: 40px;
    max-width: 150;
    width: 130px;
    max-height: 100;
    height: auto;
}

.loginInputEmail input{
    margin-block: 5px;
    border: none;
    background: #FFB77B;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 5px 25px;
}

.loginInputPassword input{
    margin-block: 5px;
    border: none;
    background: #FFB77B;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 5px 25px;

}

.forgotPassword button{
    margin-block: 5px;
    cursor: pointer;
    background: none;
    border: 0px;
    float: right;
    font-size: 10px;
}

.loginButton button{
    

    margin-block: 15px;
    padding: 4px 65px;
    cursor: pointer;
    border: 3px;
    background: #FFB77B;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 22px;
}