.popup {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-popup {
    position: absolute;
    z-index: 1;
    padding: 32px;
    width: 100%;
    height: 55%;
    max-width: 648px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: left;
    border-radius: 10px;
}

.div-confirmed-action {
    width: 100%;
    height: 100%;
}

.div-text-confirmed-action {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
}

.div-text-confirmed-action-home {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
}

.div-btns-confirmed-action {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.div-btns-confirmed-action button {
    font-size: 110%;
    font-family: Lato;
    width: 35vw;
    height: 9vh;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border: none;
}

.btn-cancel {
    background-color:#B22222;
    color: white;
}

.btn-cancel:hover {
    background-color:#8B0000;
}

.btn-confirm {
    background-color:#3CB371;
    color: white;
}

.btn-confirm:hover {
    background-color:#2E8B57;
}

@media only screen and (min-width: 425px) { 
    .div-confirmed-action {
        font-size: 100%;
    }
    .div-btns-confirmed-action button {
        height: 8vh;
    }
}

@media only screen and (min-width: 768px) { 
    .div-confirmed-action {
        font-size: 100%;
    }
    .div-btns-confirmed-action button {
        height: 9vh;
        width: 20vw;
    }
}

@media only screen and (min-width: 1024px) { 
    .div-confirmed-action {
        font-size: 100%;
    }
    .div-btns-confirmed-action button {
        height: 8vh;
    }
}

@media only screen and (min-width: 1840px) { 
    .div-confirmed-action {
        font-size: 120%;
    }
    .div-btns-confirmed-action button {
        height: 8vh;
        width: 20vw;
    }
}

@media only screen and (min-width: 2240px) { 
    .div-confirmed-action {
        font-size: 140%;
    }
    .div-btns-confirmed-action button {
        height: 7vh;
        width: 10vw;
    }
}